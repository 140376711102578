$(document).ready(function(){
"use strict";

$(document).on('submit', '#send-kommentar-form', function(){
     var id = $(this).attr('data-id');
     var cat_id = $(this).attr('data-cat');
     var form_data=JSON.stringify($(this).serializeObject());
    $.ajax({
    url: "admin/bin/ajax/forms/public-forms.php?m=save_blog_kommentar&id="+id+"&cat_id="+cat_id+" ",
    type : "POST",
    contentType : 'application/json',
    data : form_data,
    success : function(result) {
       if(result.status){
           success_message(result.msg);
           $("#send-kommentar-form").trigger("reset");
      }else{
           warning_message(result.msg);
       }
    },
    error: function(xhr, resp, text) {
        // show error to console
        console.log(xhr, resp, text);
    }
});
return false;
});
//update user-kommentar
$(document).on('click', '.send_edit_kommentar', function(){
    var id = $(this).attr('data-id');
  $.post("admin/bin/ajax/forms/public-forms.php?m=update_user_kommentar", {
     id: id,
     'kommentar': $('#edit_kommentar'+id+'').val()
  }, function(data){
    if(data.status){
      success_message(data.msg);
      $('#user_kommentar'+data.id+'').html(data.kommentar);
      $('#collapseKommentar'+data.id+'').collapse('toggle');

    }else{
      warning_message(data.msg);
    }
 });

});//button

$(document).on('click', '.delete_user_kommentar', function(){
    var id = $(this).attr('data-id');
    warn_delete_kommentar(id);

});//button
function delete_user_kommentar(id){
  $.post("admin/bin/ajax/forms/public-forms.php?m=delete_user_kommentar", {
     id: id
     }, function(data){
    if(data.status){
      success_message(data.msg);
      $('#show_kommentar_box'+data.id+'').empty();
    }else{
      warning_message(data.msg);
    }
 });
}
function warn_delete_kommentar(id) {
    swal({
    icon: "info",
    title: "Diesen Eintrag löschen?",
    text: "Diesen Eintrag wirklich löschen?",
    buttons:{
        cancel: "abbrechen",
        senden :{
            text: "löschen",
            value:'delete'
        }
    }
    }).then((value) => {
        switch(value){
            case "delete":
                //funktion aufrufen
                delete_user_kommentar(id);
                break;
        }
    });
}

});//document

//admin/bin/uploads/files/thumb/20150208-Ich-character-Portrait-neu.jpg
