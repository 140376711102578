import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import bootstrap from 'bootstrap';
import Cropper from 'cropperjs';
import swal from 'sweetalert';

/**
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * ArtPictureDesign JS Class
 * @package Projekt Website
 * Copyright 2018, Jens Wiecker
 * License: Commercial - goto https://art-picturedesign.de
 * https://art-picturedesign.de/webdesign
 *
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */

/*+++++++++++++++++++++++++++++++++
 * +++++++ SCROLLUP ++++++
 * ++++++++++++++++++++++++++++++++
 */
jQuery(document).ready(function(){
   //srollTop
     $(window).scroll(function() {
       if ($(this).scrollTop() > 100) {
         $('.scrollup').fadeIn();

       } else {
         $('.scrollup').fadeOut();
      }
     });

       $(window).scroll(function() {
       if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
       }
     });

     $('.scrollup').click(function() {
       $("html, body").animate({
         scrollTop: 0
       }, 1000);
       return false;
     });
       $('.scroll').click(function() {
       $("html, body").animate({
         scrollTop: 0
       }, 1000);
       return false;
     });

       // Skill bar Function
       jQuery('.skillbar').each(function(){
           jQuery(this).find('.skillbar-bar').animate({
               width:jQuery(this).attr('data-percent')
           },6000);
       });




   });
   /*+++++++++++++++++++++++++++++++++
    * +++++++ Preloder scripte ++++++
    * ++++++++++++++++++++++++++++++++
    */

  // jQuery(window).load(function(){'use strict';
  //     $(".preloader").delay(1600).fadeOut("slow").remove();
  // });

   /*+++++++++++++++++++++++++++++++++
    * +++++++ Formulare ++++++
    * ++++++++++++++++++++++++++++++++
    */
   $(document).ready(function(){
   "use strict";
   $("#show-response").hide();

   if($('#msg-akzept').val() == 0){
       $("#data-message-btn").attr("disabled","disabled");
   }

   if($('#form-one-akzept').val() == 0){
       $("#data_send_form_one").attr("disabled","disabled");
   }

   //checkboxen
   $('#msg-akzept').change(function() {
              if($(this).is(":checked")) {
          $('#msg-akzept').val(1);
          $("#data-message-btn").removeAttr("disabled");
          return;
          }
           $('#msg-akzept').val(0);
           $("#data-message-btn").attr("disabled","disabled");
       });

   $('#form-one-akzept').change(function() {
              if($(this).is(":checked")) {
          $('#form-one-akzept').val(1);
          $("#data_send_form_one").removeAttr("disabled");
          return;
          }
           $('#form-one-akzept').val(0);
           $("#data_send_form_one").attr("disabled","disabled");
       });

       });
   /*+++++++++++++++++++++++++++++++++
    * +++++++ Button ++++++
    * ++++++++++++++++++++++++++++++++
    */
   $(document).ready(function(){
   //BUTTON SEND FORM
   $(document).on('submit', '#send-message-user-form', function(){
        var form_data=JSON.stringify($(this).serializeObject());
       $.ajax({
       url: 'admin/bin/ajaxHandlePayload.php',
       type : "POST",
       contentType : 'application/json',
       data : form_data,
       success : function(result) {
          if(result.status){
              success_message(result.msg);
              $("#send-message-user-form").trigger("reset");
              $("#collapseNewGB").collapse('toggle');
           }else{
              warning_message(result.msg);
          }
       },
       error: function(xhr, resp, text) {
           // show error to console
           console.log(xhr, resp, text);
       }
   });

   return false;
   });
   //Formular 1 & 2

   $(document).on('submit', '#send-message-one', function(){
        var form_data=JSON.stringify($(this).serializeObject());
       $.ajax({
       url: 'admin/bin/ajaxHandlePayload.php',
       type : "POST",
       contentType : 'application/json',
       data : form_data,
       success : function(result) {
          if(result.status){
              success_message(result.msg);
              $("#send-message-one").trigger("reset");
          }else{
              warning_message(result.msg);
          }
       },
       error: function(xhr, resp, text) {
           // show error to console
           console.log(xhr, resp, text);
       }
   });

   return false;
   });
   /*+++++++++++++++++++++++++++++++++
    * +++++++ change page title ++++++
    * ++++++++++++++++++++++++++++++++
    */

   function changePageTitle(page_title){
    // change title tag
    document.title=page_title;
}

   });

   /*+++++++++++++++++++++++++++++++++
    * +++++++ Serialize ++++++
    * ++++++++++++++++++++++++++++++++
    */
   // function to make form values to json format
   $.fn.serializeObject = function()
   {
       var o = {};
       var a = this.serializeArray();
       $.each(a, function() {
           if (o[this.name] !== undefined) {
               if (!o[this.name].push) {
                   o[this.name] = [o[this.name]];
               }
               o[this.name].push(this.value || '');
           } else {
               o[this.name] = this.value || '';
           }
       });
       return o;
   };

   //Message Handle
          function error_message(msg) {
              "use strict";
                var x = document.getElementById("snackbar_error");
                $("#snackbar_error").html(msg);
                x.className = "show";
                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 4500);
                }
          function success_message(msg){
              "use strict";
                var x = document.getElementById("snackbar-success");
                $("#snackbar-success").text(msg);
                x.className = "show";
                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
                }
          function warning_message(msg){
              "use strict";
                var x = document.getElementById("snackbar-warning");
                $("#snackbar-warning").text(msg);
                x.className = "show";
                setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
                }
