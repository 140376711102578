$(document).ready(function() {
  "use strict";
  //send-profil-form
  $(document).on('submit', '#send-profil-form', function(){
       var form_data=JSON.stringify($(this).serializeObject());
       var id = $(this).attr('data-id');
       if(id == 'edit_user_email'){
         warn_change_email(form_data,id);
       }else{
       send_user_profil_form(form_data, id);
        }
       return false;
  });
function send_user_profil_form(form_data, id){
  $.ajax({
  url: 'ajax/form/user_profil.php?m='+id+'',
  type : "POST",
  contentType : 'application/json',
  data : form_data,
  success : function(result) {
     if(result.status){
         success_message(result.msg);
      }else{
         warning_message(result.msg);
     }
  },
  error: function(xhr, resp, text) {
      // show error to console
      console.log(xhr, resp, text);
  }
});
}
load_user_image();
function load_user_image(){
  var loc = window.location;
  var first_path = loc.pathname;
  var n = first_path.lastIndexOf("/") +1 ;
  var seite = first_path.substr(n).replace('.php','');

  switch(seite){
    case'user-profil':
      $.post("ajax/form/read_member_data.php", {
          'ID': profil_user_id.id,
          'METHOD': 'get_user_image'
        },
        function(data) {
          if (data.status) {
            var img_container = $("#user_profil_img");
            img_container.css("width",120);
            img_container.css("height",120);
            img_container.css("border-radius","50%");
            var u=document.getElementById("user_profil_img");
              u.width = u.scrollWidth;
              u.height = u.scrollHeight;
              var ctx=u.getContext("2d");
              var img=new Image();
              img.onload = function(){
              ctx.drawImage(img, data.x, data.y, data.width,data.height, 0, 0, 120, 120);
              };
              img.src=data.image;
          }
        });
    break;
    case'user-startseite':
    $.post("ajax/form/member-upload.php", {
        'METHOD': 'load_grop_image'
      },
      function(data) {
          var img_container = $("#user_round_img");
          img_container.css("width",120);
          img_container.css("height",120);
          img_container.css("border-radius","50%");
          var u=document.getElementById("user_round_img");
            u.width = u.scrollWidth;
            u.height = u.scrollHeight;
            var ctx=u.getContext("2d");
            var img=new Image();
            img.onload = function(){
            ctx.drawImage(img, data.x, data.y, data.width,data.height, 0, 0, 120, 120);
            };
            img.src=data.image;
      });
    break;
    case'blog-show':
      $.getJSON("admin/bin/ajax/forms/public-forms.php?m=read_blog_by_id&id="+show_img_id.id+" ", function(data) {
         $.each(data.kommentare, function(key, val){
           var img_container = $('#kommentar_user_img'+val.id+'');
           img_container.css("width",96);
           img_container.css("height",96);
           img_container.css("border-radius","50%");
            var u=document.getElementById('kommentar_user_img'+val.id+'');
            u.width = u.scrollWidth;
            u.height = u.scrollHeight;
            var ctx=u.getContext("2d");
            var img=new Image();
            img.onload = function(){
            ctx.drawImage(img, val.x, val.y, val.width,val.height, 0, 0, 96, 96);
            };
            img.src=val.image;

         });


      });
    break;
  }
}

$(document).on('click', '#delete_user_account', function(){
     var id = $(this).attr('data-id');
     warn_delete_account(id);
   });
function delete_user_account(id){
  $.getJSON("admin/bin/ajax/forms/public-forms.php?m=delete_user_account&id="+id+" ", function(data) {
    if(data.status){
      success_message(data.msg);
      var loc = window.location;
      var self_host = loc.host;
      var protocol = loc.protocol+'//';
      var first_path = loc.pathname;
      var n = first_path.indexOf("/") +1 ;
      var seite = first_path.substr(n).replace('user-startseite.php','');
      var self_url = protocol+self_host+'/'+seite+'delete-account.php?delete=1';

      window.setTimeout('window.location = "'+self_url+'" ',3000);
    }
  });
}
   function warn_delete_account(id) {
       swal({
       icon: "info",
       title: "Account wirklich löschen?",
       text: "Alle Daten und Kommentare werden gelöscht!",
       buttons:{
           cancel: "abbrechen",
           senden :{
               text: "löschen",
               value:'delete'
           }
       }
       }).then((value) => {
           switch(value){
               case "delete":
                   //funktion aufrufen
                   delete_user_account(id);
                   break;
           }
       });
   }

   function warn_change_email(data,id) {
       swal({
       icon: "info",
       title: "E-Mail wirklich ändern?",
       text: "Du kannst Dich erst nach der bestätigung wieder einloggen!",
       buttons:{
           cancel: "abbrechen",
           senden :{
               text: "E-Mail ändern",
               value:'delete'
           }
       }
       }).then((value) => {
           switch(value){
               case "delete":
                   //funktion aufrufen
                   send_user_profil_form(data,id);
                   break;
           }
       });
   }


});//document
