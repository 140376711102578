
$(document).ready(function() {
  "use strict";
  $("#back-blog-button").hide();
  $(document).on('click', '.archiv-liste', function() {
    var id = $(this).attr('data-id');
    $.getJSON("admin/bin/ajax/forms/public-forms.php?m=read_by_blog_id&id="+id+" ", function(data) {
      
      var html = '';

      $.each(data.blog_arr, function(key, val){
      html += '<div class="box-blog-news p-3">';
      html += '<b>'+val.datum+'</b><br><br>';
      html += '<div class="row ">';
      html += '<div class="col-md-2">';
      html += '<a href="blog-show.php?id='+val.blog_id+'">';
      html += '<img class="rounded mx-auto d-block mb-3" src="admin/bin/uploads/files/thumb/'+val.img+'" alt="Art-Picture Design Blog" width="150" height="150">';
      html += '</a>';
      html += '</div>';
      html += '<div class="col-md-9">';
      html += '<small><u>'+val.blog_bezeichnung+'</u></small>';
      html += '</h2>';
      html += '<p>'+val.blog_beschreibung+'</p>';
      html += '<br><br>';
      html += '<h5><a href="blog-show.php?id='+val.blog_id+'"><span class="theme-text-gray fa fa-angle-double-right"></span>&nbsp;hier </a> geht\'s zum Beitrag</h5><br>';
      html += '</div>';
      html += '</div>';
      html += '<hr class="hr-dark p-3">';
      html += '</div>';
      });

      $(".blog-archive").hide();
      $("#show-blog").show();
      $("#show-blog").html(html);
      $("#back-blog-button").show();
      $("#back-blog-button").html('<h5 id="blog-back"><span class="theme-text-color back-blog-button">zurück</span> zur Übersicht <h5><hr class="hr-dark p-3">');
   });
  });

  $(document).on('click', '#blog-back', function () {
      $(".blog-archive").show();
      $("#show-blog").hide();
      $("#back-blog-button").hide();
  });
});
